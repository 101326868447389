import { navSlide } from './hamburger';
import { login, logout } from './login';
import { updateSettings } from './updateSettings';
import { addToCart } from './shoppingCart';
import { cartStats } from './displayTotalCartItems'
import { sendOrder, getOrder, changeOrderStatus, sendOrderToWhatsApp } from './sendOrder';
import { select, animateQty } from './showCategory';
import { showPayMetod } from './showPaymethod'
import { createMenuItem, createMenuCategory } from './createMenuItem'
import { modifyMenuItem, modifyMenuCategory } from './modifyMenuItem'
import { formValidator } from './formValidator';
import { registerUser, postReview } from './registerUser';
import { sliderControl } from './reviewSlider'
import { showCart } from './shoppingCartUI';
import { checkForNewOrders, confirmNewOrderCount } from './chekForNewOrders'

const cartTotal = document.getElementById('cartTotal');
const categoryPanel = document.querySelector('.category-panel');
const createMenuItemForm = document.getElementById('createMenuItemForm');
const confirmBtn = document.querySelector('.confirmNewOrderCount');
const consoleMenu = document.getElementById('console-menu');
const deleteBtns = document.querySelectorAll('.--btn-delete');
const existingCategory = document.querySelectorAll('.existing-category');
const finalOrder = document.getElementById('finalOrder');
const hamburger = document.querySelector('.burger');
const loginForm = document.getElementById('form--login');
const logoutBtn = document.getElementById('logoutBtn');
const main = document.getElementById('main');
const menu = document.getElementById('container');
const menuItemForm = document.querySelectorAll(".console-card");
const newCategory = document.getElementById('new-category');
const newMenuCategory = document.getElementById('new-menu-category');
const newReview = document.getElementById('new-review');
const notification = document.getElementById('notification');
const notificationPopup = document.querySelector('.notification-popup');
const orderFilter = document.getElementById('orderFilter');
const pages = document.querySelectorAll('.pages');
const payOptions = document.querySelector('.info__options');
const resetBtn = document.querySelector('.reset-btn-js');
const sendOrderBtn = document.getElementById('sendOrderBtn');
const signUpBtn = document.getElementById('sign-up');
const signUpForm = document.getElementById('form__sign-up');
const showOrder = document.getElementById('showOrder');
const shoppingCartIcon = document.querySelector('.fa-shopping-cart');
const updateBtns = document.querySelectorAll('.--btn-update');
const userDataForm = document.getElementById('form-user-data');
const userPasswordForm = document.getElementById('form-user-password');
const whatsAppOrder = document.getElementById("whatsapp-order");


// back to top button
if (main) {
    let upBtn = document.createElement('button');
    let arrow = document.createElement('i');
    arrow.classList = 'fas fa-angle-double-up fa-2x';
    upBtn.className = 'scroll-up';
    upBtn.appendChild(arrow);
    let isVisible = false;
    function createUpBtn() {
        const scrolled = window.pageYOffset;
        if (scrolled > 600 && document.documentElement.scrollWidth < 900 && !isVisible) {
            isVisible = true;
            main.appendChild(upBtn);
            upBtn.addEventListener('click', () => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            });
        } else if (scrolled < 600 && document.documentElement.scrollWidth < 900 && isVisible) {
            isVisible = false;

            upBtn.remove();
        }
    }
    window.addEventListener('scroll', createUpBtn)
}
if (finalOrder) {
    const cartContent = JSON.parse(sessionStorage.getItem('pedido'));
    const cartPreview = document.querySelector('.shopping-cart-checkout');
    showCart(cartPreview, cartContent)
}
if (newReview) {
    sliderControl();
    newReview.addEventListener('submit', e => {
        e.preventDefault();

        let data = {
            menuItemId: document.getElementById('post-review').getAttribute('data-id'),
            review: document.getElementById('review-text').value,
            rating: document.getElementById('rating-control').value
        }
        postReview(data)
    })
    document.querySelector('.post-review-js').addEventListener('click', () => {
        document.querySelector('.review-area').classList.toggle('hidden');
    })
}
if (signUpForm) {
    formValidator();

    signUpForm.addEventListener('submit', e => {
        e.preventDefault();
        const data = {
            name: document.getElementById("name").value,
            email: document.getElementById("email").value,
            address: document.getElementById("address").value,
            phone: document.getElementById("phone").value,
            password: document.getElementById("password").value,
            passwordConfirm: document.getElementById("passwordConfirm").value
        }
        signUpBtn.innerText = 'Enviando su información ...'
        registerUser(data);

    })
}
if (payOptions) {
    showPayMetod();
}
if (menu) {
    const menuButtons = document.querySelector(".menu-categories");
    const nrOfPixels = 2;
    let idle = true;
    let maxRight = false;
    let prevValue = 1;
    menuButtons.addEventListener("mouseover", () => idle = false);

    setTimeout(() => {
        let animate = setInterval(() => {
            if(Math.abs(menuButtons.scrollLeft - prevValue) > nrOfPixels){
                clearInterval(animate);
                return idle = true;
            }
            if (idle && document.documentElement.scrollWidth < 600) {
                if (maxRight && prevValue > nrOfPixels) {
                    return (
                        prevValue = menuButtons.scrollLeft,
                        menuButtons.scrollLeft -= nrOfPixels
                    )
                } else if (menuButtons.scrollLeft === prevValue) {
                    return maxRight = true;
                } else if (menuButtons.scrollLeft !== prevValue) {
                    return (
                        prevValue = menuButtons.scrollLeft,
                        menuButtons.scrollLeft += nrOfPixels,
                        maxRight = false
                    )
                }
            } else {
                clearInterval(animate)
            }
        }, 200);
    }, 500);
    
    select('card', true);
    select('console-card', false);
    addToCart(menu);
    animateQty();
}
if (hamburger) {
    navSlide();
}
if (loginForm) {
    loginForm.addEventListener('submit', e => {
        e.preventDefault();

        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        login(email, password);
    });
}
if (logoutBtn) {
    logoutBtn.addEventListener('click', logout)
}
if (userDataForm) {
    userDataForm.addEventListener('submit', e => {
        e.preventDefault();
        document.querySelector('.btn--save-info').innerText = 'Actualizando ...'
        const form = new FormData();

        form.append('name', document.getElementById('my-name').value);
        form.append('email', document.getElementById('my-email').value);
        form.append('address', document.getElementById('my-address').value);
        form.append('phone', document.getElementById('phone').value);
        form.append('photo', document.getElementById('photo').files[0]);

        updateSettings(form, 'data');
        setTimeout(() => {
            document.querySelector('.btn--save-info').innerText = 'Actualizar información'
        }, 1000);
    });
}
if (userPasswordForm) {
    userPasswordForm.addEventListener('submit', async e => {
        e.preventDefault();
        document.querySelector('.btn--save-password').textContent = 'Actualizando ...'
        const passwordCurrent = document.getElementById('password-current').value;
        const password = document.getElementById('password').value;
        const passwordConfirm = document.getElementById('password-confirm').value;
        await updateSettings({ passwordCurrent, password, passwordConfirm }, 'password');

        document.querySelector('.btn--save-password').textContent = 'Guardar contraseña'
        document.getElementById('password-current').value = '';
        document.getElementById('password').value = '';
        document.getElementById('password-confirm').value = '';
    });
}
if (categoryPanel) {
    // preventing default form action
    existingCategory.forEach(form => form.addEventListener("submit", e => {
        e.preventDefault();
    }));
    newCategory.addEventListener("submit", e => {
        e.preventDefault();
    })
    newMenuCategory.addEventListener("click", async () => {
        const newCateg = { categoryName: document.getElementById("menu-category").value }
        if (newCateg.categoryName !== "") await createMenuCategory(newCateg);
    })
    // updating menu category
    updateBtns.forEach(btn => btn.addEventListener('click', async () => {
        const data = {
            id: btn.getAttribute("data-id"),
            name: document.getElementById(`${btn.getAttribute("data-name")}`).value,
            packagePrice: document.getElementById(`${btn.getAttribute("data-name")}-data-package`).value * 1,
            packageTitle: document.getElementById(`${btn.getAttribute("data-name")}-data-title`).value
        }
        await modifyMenuCategory(data, 'PATCH');
    }))

    deleteBtns.forEach(btn => btn.addEventListener('click', async () => {

        const data = {
            id: btn.getAttribute("data-id")
        }
        await modifyMenuCategory(data, 'DELETE');
    }));
}
if (consoleMenu) {
    menuItemForm.forEach(item => document.getElementById(`${item.id}`).addEventListener('submit', e => {
        e.preventDefault();
    }))

    updateBtns.forEach(btn => btn.addEventListener('click', async () => {
        btn.innerText = 'Actualización...'
        const itemId = btn.getAttribute('data-id');
        document.getElementById(`${itemId}`).addEventListener('submit', async e => {
            e.preventDefault();
        });
        let updatedQty = [
            document.getElementById(`quantity0-${itemId}`).value,
            document.getElementById(`quantity1-${itemId}`).value,
            document.getElementById(`quantity2-${itemId}`).value
        ];

        let updatedPrice = [];
        if (document.getElementById(`price0-${itemId}`).value && document.getElementById(`price0-${itemId}`).value !== null) updatedPrice.push(document.getElementById(`price0-${itemId}`).value * 1);
        if (document.getElementById(`price1-${itemId}`).value && document.getElementById(`price1-${itemId}`).value !== null) updatedPrice.push(document.getElementById(`price1-${itemId}`).value * 1);
        if (document.getElementById(`price2-${itemId}`).value && document.getElementById(`price2-${itemId}`).value !== null) updatedPrice.push(document.getElementById(`price2-${itemId}`).value * 1);

        const form = new FormData();
        form.append('id', itemId);
        form.append('name', document.getElementById(`name-${itemId}`).value);
        form.append('description', document.getElementById(`description-${itemId}`).value);
        form.append('bigDescription', document.getElementById(`big-description-${itemId}`).value);
        form.append('category', document.getElementById(`category-${itemId}`).value);
        form.append('quantity', JSON.stringify(updatedQty));
        form.append('price', JSON.stringify(updatedPrice));
        if (document.getElementById(`photo-${itemId}`).files[0] !== undefined) {
            form.append('photo', document.getElementById(`photo-${itemId}`).files[0]);
        }
        await modifyMenuItem(form, 'PATCH');

        btn.innerHTML = '<i class="fas fa-check"></i>'
    }))
    deleteBtns.forEach(btn => btn.addEventListener('click', async () => {
        const itemId = btn.getAttribute('data-id');
        const form = new FormData();
        form.append('id', itemId)
        await modifyMenuItem(form, 'DELETE');
        btn.parentElement.parentElement.parentElement.parentElement.remove();
    }))
}
if (shoppingCartIcon) {
    shoppingCartIcon.addEventListener('click', () => {
        if (cartTotal.innerText !== "0") {
            const cartContent = JSON.parse(sessionStorage.getItem('pedido'));
            const cartPreview = document.querySelector('.shopping-cart-prew');
            const cartOrder = document.getElementById('cart-order');
            const closeCart = document.querySelector('.close-cart');
            cartPreview.classList.add('--cart-active');

            showCart(cartOrder, cartContent);
            closeCart.addEventListener('click', () => {
                cartPreview.classList.remove('--cart-active');
            });
        }
    })
}
if (shoppingCartIcon) {
    cartStats();
}
if (sendOrderBtn) {
    finalOrder.addEventListener('submit', e => {
        e.preventDefault();
    })
    sendOrderBtn.addEventListener('click', async () => {

        const getOrder = document.getElementsByName('gettingOrder');
        const payMethod = document.getElementsByName('payMethod');

        let getOrderValue;
        let payMethodValue;

        if (getOrder[0].checked) {
            getOrderValue = getOrder[0].value;
        }
        if (getOrder[1].checked) {
            getOrderValue = getOrder[1].value;
        }

        if (payMethod[0].checked) payMethodValue = payMethod[0].value;
        if (payMethod[1].checked) payMethodValue = payMethod[1].value;
        if (payMethod[2].checked) payMethodValue = payMethod[2].value;

        let menuItem = [];
        const itemList = JSON.parse(sessionStorage.getItem('pedido'));

        if (itemList === null) return
        const filteredObj = (obj, ...allowedFileds) => {
            const newObj = {};
            Object.keys(obj).forEach(el => {
                if (allowedFileds.includes(el)) newObj[el] = obj[el];
            });
            return newObj;
        }
        itemList.forEach(item => {
            menuItem.push(filteredObj(item, '_id', 'count', 'position'));
        });
        const data = {
            menuItem,
            // name: document.getElementById('name').value,
            deliveryAddress: document.getElementById('address').value,
            phone: document.getElementById('phone').value,
            comment: document.getElementById('comment').value,
            gettingOrder: getOrderValue,
            payMethod: payMethodValue
        }
        await sendOrder(data);
    });
}
if (whatsAppOrder) {
    finalOrder.addEventListener('submit', e => {
        e.preventDefault();
    });
    finalOrder.addEventListener("keyup", sendOrderToWhatsApp);
}
if (createMenuItemForm) {
    createMenuItemForm.addEventListener('submit', async e => {
        e.preventDefault();
        const form = new FormData();

        let priceSet = [];
        if (document.getElementById("price0").value) priceSet.push(document.getElementById("price0").value * 1);
        if (document.getElementById("price1").value) priceSet.push(document.getElementById("price1").value * 1);
        if (document.getElementById("price2").value) priceSet.push(document.getElementById("price2").value * 1);
        // const priceSet = [document.getElementById('price0').value * 1, document.getElementById('price1').value * 1, document.getElementById('price2').value * 1];
        const qtySet = [document.getElementById('quantity0').value, document.getElementById('quantity1').value, document.getElementById('quantity2').value];

        form.append('name', document.getElementById("name").value)
        form.append('description', document.getElementById("description").value)
        form.append('category', document.getElementById("category").value)
        form.append('quantity', JSON.stringify(qtySet))
        form.append('price', JSON.stringify(priceSet))
        form.append('photo', document.getElementById("photo").files[0])

        await createMenuItem(form);
    });
}
if (notification) {
    setTimeout(function () {
        checkForNewOrders();
    }, 1500);
    setInterval(function () {
        checkForNewOrders();
    }, 120 * 1000);
    confirmBtn.addEventListener('click', () => {
        confirmNewOrderCount();
        notificationPopup.classList.add('hidden');
    });
}
//order console page
if (orderFilter) {
    orderFilter.addEventListener('submit', e => {
        e.preventDefault();
    });
    showOrder.addEventListener('click', async () => {
        const data = {
            startDay: document.getElementById('startDay').value,
            endDay: document.getElementById('endDay').value,
            orderNumber: document.getElementById('orderNumber').value,
            payMethod: document.getElementById('payMethod').value,
            gettingOrder: document.getElementById('gettingOrder').value,
            total: document.getElementById('total').value,
            isDelivered: document.getElementById('delivered').checked,
            page: 1
        }
        await getOrder(data);
        const confirmDeliveryButtons = document.querySelectorAll(".delivered");

        confirmDeliveryButtons.forEach(button => button.addEventListener('click', async () => {
            const itemId = button.getAttribute('data-id');
            const data = {
                id: itemId,
                isDelivered: true
            }
            await changeOrderStatus(data, 'PATCH');
            button.parentElement.parentElement.remove();
        }));
    });
    resetBtn.addEventListener('click', () => {
        document.getElementById('startDay').value = "";
        document.getElementById('endDay').value = "";
    })

}
//order display
if (pages) {
    pages.forEach(page => page.addEventListener('click', async e => {
        let currentPage = e.target
        if (currentPage.classList.contains('page-button')) {
            const nextPage = {
                startDay: document.getElementById('startDay').value,
                endDay: document.getElementById('endDay').value,
                orderNumber: document.getElementById('orderNumber').value,
                payMethod: document.getElementById('payMethod').value,
                gettingOrder: document.getElementById('gettingOrder').value,
                total: document.getElementById('total').value,
                isDelivered: document.getElementById('delivered').checked,
                page: currentPage.getAttribute('page-id')
            }
            await getOrder(nextPage);
        }
    }))
}