export const addToCart = element => {
    element.addEventListener('click', e => {
        const item = e.target;
        // copy data from local storage into array
        let shoppingCart = JSON.parse(sessionStorage.getItem('pedido'));
        //setting the array as empty if target value of local storage is null
        if (!shoppingCart) shoppingCart = []
        //if the clicked element conains the text Add, execute the if block
        if (item.classList.contains('fa-cart-arrow-down')) {
            let selected = item.parentElement.parentElement.childNodes[1].value || item.parentElement.parentElement.childNodes[1].getAttribute('value');
            let currentItem = {
                count: 1,
                _id: item.parentElement.getAttribute('data-id'),
                name: item.parentElement.getAttribute('data-name'),
                position: selected,
                category: item.parentElement.getAttribute('data-category'),
                qty: item.parentElement.getAttribute(`data-qty${selected}`),
                price: item.parentElement.getAttribute(`data-price${selected}`) * 1,
                packagePrice: item.parentElement.getAttribute('data-package'),
                packageTitle: item.parentElement.getAttribute('data-title'),
                photo: item.parentElement.getAttribute('data-photo')
            }
            //this function verifies if the shoppingCart conains the same _id as the clicked item
            const doesExist = elem => elem._id === currentItem._id && elem.position === currentItem.position
            const result = shoppingCart.findIndex(doesExist);
            if (result !== -1) {
                shoppingCart[result].count++
                shoppingCart[result].price = currentItem.price * shoppingCart[result].count
            } else if (result === -1) {
                shoppingCart.push(currentItem);
            }
            cartTotal.innerText = sessionStorage.getItem('totalItems');
            let totalItems = sessionStorage.getItem('totalItem');
            totalItems = shoppingCart.reduce((currentTotal, item) => { return item.count + currentTotal }, 0) + totalItems;
            sessionStorage.setItem('totalItems', totalItems);
            sessionStorage.setItem('pedido', JSON.stringify(shoppingCart));
            cartTotal.innerText = sessionStorage.getItem('totalItems');

            let purchaseAnimation = item.parentElement.parentElement.parentElement.parentElement.parentElement.childNodes[0];
            let confirmAddedDiv = document.createElement('div');
            let cartAddedSymbol = document.createElement('i');
            confirmAddedDiv.appendChild(cartAddedSymbol);
            cartAddedSymbol.className = 'fas fa-cart-plus'
            confirmAddedDiv.classList = 'added';
            confirmAddedDiv.style.animation = 'menuCardFadeInUp 250ms ease forwards';
            purchaseAnimation.appendChild(confirmAddedDiv);
            setTimeout(() => {
                confirmAddedDiv.style.animation = 'menuCardFadeOutDown 750ms ease forwards';
                purchaseAnimation.removeChild(confirmAddedDiv);
            }, 750);
        }
    });
}