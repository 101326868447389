export const hideAlert = () => {
    const el = document.querySelector('.alert');
    // if (el) {
    //     el.parentElement.removeChild(el);
    // }
    if (el.innerText) {
        el.innerText = '';
    }
}


export const showAlert = (type, message) => {
    hideAlert();
    const errorMsg = document.querySelector('.alert');
    errorMsg.className = `alert alert--${type}`
    errorMsg.innerText = `${message}`
    // const markup = `<div class="alert alert--${type}">${message}</div>`;
    // document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
    window.setTimeout(hideAlert, 3000);
}