import axios from 'axios'
import { showAlert } from './alerts'

//type is either 'password' or 'data'
export const createMenuItem = async data => {
    try {
        const url = 'http://127.0.0.1:3000/api/menu'
        const res = await axios({
            method: 'POST',
            url,
            data
        });
        if (res.data.status === 'success') location.reload();
    } catch (err) {
        showAlert('error', err)
    }
}

export const createMenuCategory = async data => {
    try {
        const url = 'http://127.0.0.1:3000/api/categories'
        const res = await axios({
            method: 'POST',
            url,
            data
        });
        if (res.data.status === 'success') {
            if (res.data.status === 'success') location.reload();

            // showAlert('success', 'Menu item created successfully');
        }
    } catch (err) {
        showAlert('error', err)
    }
}