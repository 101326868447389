import axios from 'axios'
import { showAlert } from './alerts'

//type is either 'password' or 'data'
export const updateSettings = async (data, type) => {
    try {
        const url = type === 'password' ? 'http://127.0.0.1:3000/api/users/updateMyPassword' : 'http://127.0.0.1:3000/api/users/updateMyInfo'
        const res = await axios({
            method: 'PATCH',
            url,
            data
        });
        if (res.data.status === 'success') {
            location.reload();
        }
    } catch (err) {
        // showAlert('error', err)
    }
}