import axios from 'axios';
import { showAlert } from './alerts';
const multiply = String.fromCodePoint(0x00D7);

export function sendOrderToWhatsApp() {
    /* : = %3A; / = %2F; ? = %3F; = = %3D; + = %2B; " = %22; Enter = %0A; Space = %20; */

    const phone = "34665799476";
    const name = document.getElementById('name').value;
    const address = document.getElementById('address').value;

    const order = JSON.parse(sessionStorage.getItem('pedido'));

    const redactedOrder = order.map((item, i) => `${i + 1}.+${item.name.split(" ").join("+")}+${item.qty.split(" ").join("+")}%3A+${item.count}+${multiply}+${item.packageTitle ? `%28+${item.price / item.count}%E2%82%AC+%2B+${item.packagePrice}%E2%82%AC+${item.packageTitle.split(" ").join("+")}+%29` : `${item.price / item.count}%E2%82%AC`}`
    );
    const orderStringFormat = redactedOrder.reduce((order, item) => order += `${item}%0A`, "");
    const total = document.querySelector(".cart__total");

    const whatAppLink = `https://api.whatsapp.com/send/?phone=${phone}&text=https%3A%2F%2Fmaps.google.com%2F%3Fq%3D${address.split(" ").join("%2B")}%2BZamora%2BEspa%C3%B1a%0A${orderStringFormat}%0A${total.innerText.split(" ").join("+")}%0A%0A${name
        .split(" ").join("+")}%0A%22${address.split(" ").join("+")}%22`;

    if (name.length > 2 && address.length > 8) {
        document.getElementById("whatsapp-order").setAttribute("href", whatAppLink);
    } else {
        document.getElementById("whatsapp-order").setAttribute("href", "");
    }
}

export const sendOrder = async (data) => {
    try {
        const url = 'http://127.0.0.1:3000/api/orders'
        const res = await axios({
            method: 'POST',
            url,
            data
        });

        if (res.data.status === 'success') {
            showAlert('success', 'Gracias por comprar!');
            sendOrderBtn.innerText = "Pedido enviado";
            sendOrderBtn.disabled = true;
            // document.getElementById('name').value = "";
            document.getElementById('address').value = "";
            document.getElementById('phone').value = "";
            document.getElementById('comment').value = "";
            document.querySelector('.shopping-cart-checkout').innerHTML = '';
            // document.getElementById('gettingOrder').value = "";
            // document.getElementById('payMethod').value = "";

            let info = await res.data.data;
            sessionStorage.removeItem('pedido');
            sessionStorage.removeItem('totalItems');
            document.getElementById('bizum-summary').innerHTML = `<span id="customerOrder">665 799 476 - Número de orden: ${info.orderNumber}. Total: ${info.total} €</span><button id="copyOrder">Copiar<button>`

            const orderInfoText = document.getElementById('customerOrder');
            const copyOrder = document.getElementById('copyOrder');
            copyOrder.addEventListener('click', () => {
                window.navigator.clipboard.writeText(orderInfoText.innerText);
                copyOrder.innerText = 'Copiado'
            });
        }
    } catch (err) {
        showAlert('error', err)
    }
}

export const getOrder = async (data) => {
    try {
        if (data.startDay) data.startDay = `createdAt[gte]=${data.startDay}&`
        if (data.endDay) data.endDay = `createdAt[lte]=${data.endDay}&`
        if (data.orderNumber) data.orderNumber = `orderNumber=${data.orderNumber}&`
        if (data.payMethod) data.payMethod = `payMethod=${data.payMethod}&`
        if (data.gettingOrder) data.gettingOrder = `gettingOrder=${data.gettingOrder}&`
        if (data.total) data.total = `total=${data.total}&`
        if (data.page) data.page = `page=${data.page}&`
        let delivered = `isDelivered=${data.isDelivered}`;

        const url = `http://127.0.0.1:3000/api/orders?${data.startDay}${data.endDay}${data.orderNumber}${data.payMethod}${data.gettingOrder}${data.total}${data.page}${delivered}`
        const res = await axios({
            method: 'GET',
            url,
            data
        });
        if (res.data.status === 'success') {
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            document.getElementById('searchResult').innerText = `${res.data.results} pedidos encontrados`
            orderContainer.innerHTML = '';
            if (res.data.data !== undefined) {
                const orders = res.data.data;
                let elem = orders.map(order => order.order.map(item => {
                    if (item.category === 'arroces') {
                        return `
                        <p class="order-container__item">${item.count} ${multiply} <span class="order-container__item-price">${item.price} € - </span><span class="order-container__name">${item.name}</span><span class="order-container__highlight"> - ${item.quantity}</span></p>
                        <p class="order-container__paella-price">${item.count} ${multiply} ${item.packagePrice} € - fianza paellera </p>
                        <p class="order-container__item-price">${item.count * item.price + item.count * item.packagePrice} €</p>
                        `
                    }
                    else if (item.quantity[0] !== "") {
                        return `<p class="order-container__item"> ${item.count} ${multiply} <span class="order-container__item-price"> ${item.price} € - </span><span class="order-container__name"> ${item.name}</span><span class="order-container__highlight"> - ${item.quantity}</span></p>
                        <p class="order-container__item-price">${item.count * item.price} €</p>`
                    } else {
                        return `<p class="order-container__item"> ${item.count} ${multiply} <span class="order-container__item-price"> ${item.price} €</span><span class="order-container__name"> - ${item.name}</span></p>
                        <p class="order-container__item-price">${item.count * item.price} €</p>`
                    }
                }));
                let content = orders.map((order, i) =>
                    `
                            <div class="order-container" style="animation: menuCardFadeInUp 750ms ease forwards ${i / 9 + 0.2}s;">
                                <div class="order-container__heading">
                                    <h3 class="order-container__title">Número de orden: ${order.orderNumber}</h3>
                                </div>
                            <div class="order-container__content">
                            <p class="order-container__detail-item">Comentario:</p>
                            <p class="order-container__comment">${order.comment}</p>
                                <div class="order-container__items">
                                            ${elem[i].join('')}
                                </div>
                            </div>
                            <div class="order-container__details">
                            ${order.isDelivered ? `<span></span>` : `<button class="delivered" data-id="${order._id}">Entregado</button>`}
                                <h3 class="order-container__total">Total: ${order.total} €</h3>
                                <p class="order-container__detail-item">Teléfono: ${order.user.phone}<a href='tel://${order.user.phone}'><i class="fas fa-phone"></i></a></p>
                                <p class="order-container__detail-item">El pedido será: <span class="order-container__highlight">${order.gettingOrder}</span></p>
                                <p class="order-container__detail-item">Método de pago: <span class="order-container__highlight">${order.payMethod}</span></p>
                                ${order.gettingOrder !== 'restaurante' ? `<p class="order-container__detail-item">Dirección de entrega:<a class="google-map-link" href="https://maps.google.com/?q=${order.deliveryAddress.split(".").join("").split(",").join("").split(" ").join("+")}+Zamora+España" target="_blank"><i class="fas fa-map-marker-alt"></i></a></p><h4 class="order-container__detail-item">${order.deliveryAddress}</h4>` : `<p class="order-container__detail-item"> </p><h4 class="order-container__detail-item"> </h4>`}
                                <p class="order-container__timestamp">${new Date(order.createdAt).toLocaleDateString('es-ES', options)}</p>
                            </div>
                        </div >
                    `);
                let numberOfPages = Math.ceil(res.data.results / 9);
                const pages = document.querySelectorAll('.pages')
                pages[0].innerHTML = '';
                pages[1].innerHTML = '';
                for (let i = 0; i < numberOfPages; i++) {
                    let pageButton;
                    if (data.page === `page=${i + 1}&`) pageButton = `<button class="page-button clicked" page-id="${i + 1}">${i + 1}</button>`
                    else pageButton = `<button class="page-button" page-id="${i + 1}">${i + 1}</button>`
                    pages[0].innerHTML += pageButton;
                    pages[1].innerHTML += pageButton;
                }
                orderContainer.innerHTML = content.join('');
            }
        }
    } catch (err) {
        // if (res.data.data !== undefined) showAlert('error', err)
    }
}

export const changeOrderStatus = async (data, action) => {
    try {
        const url = `http://127.0.0.1:3000/api/orders/${data.id}`
        const res = await axios({
            method: action,
            url,
            data
        });

        if (res.data.status === 'success') {
            // showAlert('success', 'Menu updated successfully');
        }
    } catch (err) {
        // showAlert('error', err)
    }
}