import axios from 'axios'
import { showAlert } from './alerts'

//type is either 'password' or 'data'
export const modifyMenuItem = async (data, action) => {
    try {
        const url = `http://127.0.0.1:3000/api/menu/${data.get('id')}`
        const res = await axios({
            method: action,
            url,
            data
        });

        if (res.data.status === 'success') {
            // showAlert('success', 'Menu updated successfully');
        }
    } catch (err) {
        console.log(err);
    }
}
export const modifyMenuCategory = async (data, action) => {
    console.log(data, action);
    try {
        const url = `http://127.0.0.1:3000/api/categories/${data.id}`
        const res = await axios({
            method: action,
            url,
            data
        });

        if (res.data.status === 'success') {
            location.reload();
        }
    } catch (err) {
        console.log(err);
    }
}