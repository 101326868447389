
export const select = (elemClass, animation = true) => {
    const cards = Array.from(document.getElementsByClassName(elemClass));
    const categoryButtons = document.querySelectorAll('.--btn-default');

    const makeCardsVisible = (array, compareElement, visibleCards = []) =>
        array.filter(card => {
            if (card.getAttribute('data-category') === compareElement.getAttribute('data-category')) {
                card.classList.remove('hidden');
                return visibleCards.push(card);
            } else {
                card.classList.add('hidden');
            }
        });

    categoryButtons.forEach(button => button.addEventListener('click', () => {
        if (button.classList.contains('--btn-default')) {
            categoryButtons.forEach(button => button.classList.remove('--btn-active'));
        }
        button.classList.add('--btn-active');
        if (document.documentElement.scrollWidth > 1100 && animation) {
            makeCardsVisible(cards, button).forEach((card, i, array) => {
                if (i === array.length - 1 && i % 2 == 0) {
                    card.style.animation = `menuCardFadeInUp 750ms ease forwards`;
                }
                else if (i % 2 == 0) {
                    card.style.animation = `menuCardFadeInLeft 750ms ease forwards`;
                }
                else if (i % 2 != 0) {
                    card.style.animation = `menuCardFadeInRight 750ms ease forwards`;
                }
            })
        } else {
            makeCardsVisible(cards, button).forEach(card => card.style.animation = `menuCardFadeInUp 750ms ease forwards`);
        }
    }));
}

export const animateQty = () => {
    const shoppinCartBtns = document.querySelectorAll('.fa-2x');
    const shoppinCartIcon = document.querySelector('.fa-shopping-cart');
    const cartTotal = document.getElementById('cartTotal');

    shoppinCartBtns.forEach(cartBtn => cartBtn.addEventListener('click', () => {
        cartTotal.innerHTML < 9 ? cartTotal.style.left = 'calc(50% - 4px)' : cartTotal.style.left = 'calc(50% - 6px)'
        cartTotal.style.fontSize = '1.5rem';
        shoppinCartIcon.style.fontSize = '3.4rem';
        shoppinCartIcon.style.color = '#2cca4a';
        setTimeout(() => {
            cartTotal.style.fontSize = '1.2rem';
            cartTotal.style.left = 'calc(50% - 4px)';
            shoppinCartIcon.style.fontSize = '3rem';
            shoppinCartIcon.style.color = 'seagreen';
        }, 500);
    }))
}