export const showPayMetod = () => {
    const payButtons = document.querySelectorAll('.option__btn');
    const payDescription = document.querySelectorAll('.info__description');

    payButtons.forEach(button => button.addEventListener('click', e => {
        payButtons.forEach((button, i) => {
            if (e.target.getAttribute("data-id") == i + 1) {
                button.classList.add('--active');
                payDescription[i].classList.remove('hidden');
            } else {
                button.classList.remove('--active');
                payDescription[i].classList.add('hidden');
            }
        })
    })

    )
}