import axios from 'axios';
const confirmBtn = document.querySelector('.confirmNewOrderCount');
const notificationPopup = document.querySelector('.notification-popup');
const numberOfNewOrders = document.querySelector('.numberOfNewOrders');
const orderFilter = document.getElementById('orderFilter');

export const checkForNewOrders = async () => {
    try {
        const url = 'http://127.0.0.1:3000/api/orders/check-for-new-orders'
        const res = await axios({
            method: 'GET',
            url
        });
        if (res.data.newOrder.status) {
            let newOrderSound = new Audio("/sounds/juntos.mp3");
            newOrderSound.play();
        }
        if (res.data.newOrder.qty !== 0) {
            res.data.newOrder.qty > 1 ? numberOfNewOrders.innerText = `${res.data.newOrder.qty} nuevos pedidos` : numberOfNewOrders.innerText = `${res.data.newOrder.qty} nuevo pedido`;
            confirmBtn.style.visibility = "visible";
        }
        if (!orderFilter && res.data.newOrder.qty !== 0) {
            notificationPopup.classList.remove('hidden');
            confirmBtn.style.visibility = "visible"
            res.data.newOrder.qty > 1 ? numberOfNewOrders.innerText = `${res.data.newOrder.qty} nuevos pedidos` : numberOfNewOrders.innerText = `${res.data.newOrder.qty} nuevo pedido`;
        }
    } catch (err) {
        console.log('error', err)
    }
}
export const confirmNewOrderCount = async () => {
    try {
        const url = 'http://127.0.0.1:3000/api/orders/check-for-new-orders'
        const res = await axios({
            method: 'POST',
            url,
        });

        if (res.data.status === 'success') {
            numberOfNewOrders.innerText = "verificando nuevos pedidos";
            confirmBtn.style.visibility = "hidden";
        }
    } catch (err) {
        console.log('error', err)
    }
}