export const navSlide = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.side-nav');
    const navLinks = document.querySelectorAll('.nav__item');

    burger.addEventListener('click', () => {
        nav.classList.toggle('nav-active');

        navLinks.forEach((link, i) => {
            if (link.style.animation) {
                link.style.animation = '';
            } else {
                link.style.animation = `menuCardFadeInLeft 500ms ease forwards ${i / 9 + 0.2}s`;
            }
        });

        burger.classList.toggle('toggle');
    });
}