const multiply = String.fromCodePoint(0x00D7);
const cartTotal = document.getElementById('cartTotal');

export function showCart(parentElement, data) {
    parentElement.innerHTML = '';
    let totalPrice = document.createElement('div');
    totalPrice.className = 'cart__total';
    if (data === null) return;
    data.forEach(item => {
        let cart = document.createElement('div');
        let cartItem = document.createElement('div');
        let cartItemInfo = document.createElement('div');
        let closeCart = document.createElement('div');
        cartItemInfo.className = 'cart-item-info';
        cartItem.className = 'cart-prew';
        closeCart.className = 'close-cart';
        cart.appendChild(cartItem);
        let itemImg = document.createElement('img');
        itemImg.src = item.photo;
        itemImg.className = 'photo-prew';
        cartItem.appendChild(itemImg);

        let cartItemTitle = document.createElement('h3');
        cartItemTitle.classList.add('cart-title');
        cartItemTitle.innerText = `${item.name} ${item.qty}`;

        let counter = document.createElement('div');

        let minus = document.createElement('button');
        minus.addEventListener('click', decrementItem);
        minus.className = 'cart__btn btn';
        minus.innerText = '-';
        minus.setAttribute('data-id', item._id);
        minus.setAttribute('position', item.position);

        let qty = document.createElement('span');
        qty.className = 'cart__qty';
        qty.innerText = item.count;

        let plus = document.createElement('button');
        plus.addEventListener('click', incrementItem);
        plus.className = 'cart__btn btn';
        plus.innerText = '+';
        plus.setAttribute('data-id', item._id);
        plus.setAttribute('position', item.position);

        let remove = document.createElement('button');
        remove.innerText = multiply;
        remove.className = 'cart__btn --btn-close';
        remove.setAttribute('data-id', item._id);
        remove.setAttribute('position', item.position);
        remove.addEventListener('click', removeItem);
        counter.appendChild(minus);
        counter.appendChild(qty);
        counter.appendChild(plus);
        counter.appendChild(remove);

        let cartItemPrice = document.createElement('span');
        cartItemPrice.classList.add('cart-price');
        let displayOption = item.packagePrice !== "0" ? ` + ${item.packagePrice} € (${item.packageTitle})` : ``;
        cartItemPrice.innerText = ` ${multiply} ${item.price / item.count} €${displayOption}`;

        cartItemInfo.appendChild(cartItemTitle);
        cartItemInfo.appendChild(counter);
        counter.appendChild(cartItemPrice);
        cartItem.appendChild(cartItemInfo);
        parentElement.appendChild(cartItem);
    })

    totalPrice.innerText = `Total: ${calcTotalPrice(data)}€`;
    if (calcTotalPrice(data)) parentElement.appendChild(totalPrice);
    function incrementItem(e) {
        e.preventDefault();
        if (cartTotal) { cartTotal.innerText = sessionStorage.getItem('totalItems') };
        let itemId = e.target.getAttribute('data-id');
        let itemPosition = e.target.getAttribute('position');

        let found = data.findIndex(elem => elem._id === itemId && elem.position === itemPosition);
        data[found].price = data[found].price / data[found].count;
        data[found].count++;
        data[found].price = data[found].price * data[found].count;
        sessionStorage.setItem('totalItems', calcTotalAmount(data));
        let newCart = JSON.stringify(data);
        sessionStorage.setItem('pedido', newCart);
        if (cartTotal) { cartTotal.innerText = sessionStorage.getItem('totalItems') };
        totalPrice.innerText = `Total: ${calcTotalPrice(data)}€`;
        showCart(parentElement, data);
    }
    function decrementItem(e) {
        e.preventDefault();
        if (cartTotal) { cartTotal.innerText = sessionStorage.getItem('totalItems') };
        let itemId = e.target.getAttribute('data-id');
        let itemPosition = e.target.getAttribute('position');

        let found = data.findIndex(elem => elem._id === itemId && elem.position === itemPosition);
        data[found].price = data[found].price / data[found].count;
        if (data[found].count >= 2) {
            data[found].count--;
            data[found].price = data[found].price * data[found].count;
        } else {
            return
        }
        sessionStorage.setItem('totalItems', calcTotalAmount(data));
        let newCart = JSON.stringify(data);
        sessionStorage.setItem('pedido', newCart);
        totalPrice.innerText = `Total: ${calcTotalPrice(data)}€`;
        if (cartTotal) { cartTotal.innerText = sessionStorage.getItem('totalItems') };
        showCart(parentElement, data);
    }
    function removeItem(e) {
        e.preventDefault();
        if (cartTotal) { cartTotal.innerText = sessionStorage.getItem('totalItems') };
        let itemId = e.target.getAttribute('data-id');
        let itemPosition = e.target.getAttribute('position');
        let found = data.findIndex(elem => elem._id === itemId && elem.position === itemPosition);
        data.splice(found, 1);
        if (cartTotal) { cartTotal.innerText = calcTotalAmount(data) };
        if (cartTotal) { sessionStorage.setItem('totalItems', calcTotalAmount(data)) };
        let newCart = JSON.stringify(data);
        sessionStorage.setItem('pedido', newCart);
        totalPrice.innerText = `Total: ${calcTotalPrice(data)}€`;
        showCart(parentElement, data);
    }
    function calcTotalAmount(refArray) {
        const total = refArray.reduce((currentTotal, item) => { return item.count + currentTotal }, 0);
        return total;
    }
    function calcTotalPrice(refArray) {
        const total = refArray.reduce((currentTotal, item) => {
            return item.price + item.packagePrice * item.count + currentTotal
        }, 0);
        return total;
    }
}
