import axios from 'axios'
import { showAlert } from './alerts'

//type is either 'password' or 'data'
export const registerUser = async (data) => {
    try {
        const url = 'http://127.0.0.1:3000/api/users/signup';
        const res = await axios({
            method: 'POST',
            url,
            data
        });

        if (res.data.status === 'success') {

            document.getElementById('sign-up').innerText = 'Se creó la cuenta!';
            showAlert('success', `Se ha enviado un correo electrónico a ${document.getElementById("email").value}`);

            document.getElementById("name").value = "";
            document.getElementById("name").parentElement.className = "form__group";
            document.getElementById("email").value = "";
            document.getElementById("email").parentElement.className = "form__group";
            document.getElementById("address").value = "";
            document.getElementById("address").parentElement.className = "form__group";
            document.getElementById("phone").value = "";
            document.getElementById("phone").parentElement.className = "form__group";
            document.getElementById("password").value = "";
            document.getElementById("password").parentElement.className = "form__group";
            document.getElementById("passwordConfirm").value = "";
            document.getElementById("passwordConfirm").parentElement.className = "form__group";
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
        document.getElementById('sign-up').innerText = 'Error!'
        document.getElementById('sign-up').style.color = '#fff'
        document.getElementById('sign-up').style.backgroundColor = '#9e1e2f'
        document.getElementById('sign-up').style.border = '2px solid #9e1e2f';
    }
}

export const postReview = async (data) => {
    try {
        const url = `http://127.0.0.1:3000/api/menu/${data.menuItemId}/reviews`;
        const res = await axios({
            method: 'POST',
            url,
            data
        });

        if (res.data.status === 'success') {
            document.getElementById('review-text').value = "";
            location.replace(`/menu/${res.data.result}`)
        }
    } catch (err) {
        console.log(err);
    }
}