export const formValidator = () => {
  const signUp = document.getElementById("sign-up");
  const signUpForm = document.getElementById("form__sign-up");

  const name = document.getElementById("name");
  const email = document.getElementById("email");
  const address = document.getElementById("address");
  const phone = document.getElementById("phone");
  const password = document.getElementById("password");
  const passwordConfirm = document.getElementById("passwordConfirm");
  const legal = document.getElementById("legal");

  function setErrorFor(input, message) {
    const formGroup = input.parentElement;
    const small = formGroup.querySelector("small");

    //add error message inside small
    small.innerText = message;

    //add error class
    formGroup.className = "form__group error";
  }

  function setSuccessFor(input) {
    const formGroup = input.parentElement;
    formGroup.className = "form__group success";
  }

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  function isPhoneNr(value) {
    var str = value.toString().replace(/\s/g, '');
    return str.length === 9 && /^[679]{1}[0-9]{8}$/.test(str);
  }
  name.addEventListener("input", checkNameInput);
  email.addEventListener("input", checkEmailInput);
  address.addEventListener("input", checkAddressInput);
  phone.addEventListener("input", checkPhoneInput);
  password.addEventListener("input", checkPasswordInput);
  passwordConfirm.addEventListener("input", checkPasswordConfirmInput)

  const checkIfValid = () => {
    if (checkNameInput() && checkEmailInput() && checkAddressInput() && checkPhoneInput() && checkPasswordInput() && checkPasswordConfirmInput()) {
      signUp.style.background = '#62c257';
      signUp.innerText = 'Enviar'
      signUp.style.color = '#fff';
      signUp.style.border = '2px solid #62c257';
      signUp.style.cursor = 'pointer';
      signUp.disabled = false;
    } else {
      signUp.disabled = true;
      signUp.innerText = 'Enviar';
      signUp.style.cursor = 'no-drop';
      signUp.style.background = '#fefefe';
      signUp.style.color = '#62c257';
    }
  }

  signUpForm.addEventListener('input', checkIfValid)


  function checkNameInput() {
    if (!name.value) {
      setErrorFor(name, "Por favor inserte un nombre");
      return false;
    } else if (!isNaN(name.value)) {
      setErrorFor(name, "El nombre debe contener solo letras");
      return false;
    } else if (name.value.length < 3) {
      setErrorFor(name, "El nombre debe tener al menos 3 letras");
      return false;
    } else {
      setSuccessFor(name);
      return true;
    }
  }

  function checkEmailInput() {

    if (!email.value) {
      setErrorFor(email, "El Email no puede estar vacío");
      return false;
    } else if (!isEmail(email.value)) {
      setErrorFor(email, "El Email no es válido");
      return false;
    } else {
      setSuccessFor(email);
      return true;
    }
  }

  function checkAddressInput() {
    if (!address.value) {
      setErrorFor(address, "La dirección no puede estar vacía");
      return false;
    } else if (address.value.length < 8) {
      setErrorFor(address, "debe tener al menos 8 caracteres");
      return false;
    } else {
      setSuccessFor(address);
      return true;
    }
  }

  function checkPhoneInput() {
    if (!isPhoneNr(phone.value)) {
      setErrorFor(phone, "El número de teléfono no es válido");
      return false;
    } else {
      setSuccessFor(phone);
      return true;
    }
  }
  function checkPasswordInput() {
    if (password.value.length < 8) {
      setErrorFor(password, "debe tener al menos 8 caracteres");
      return false;
    } else {
      setSuccessFor(password);
      return true;
    }
  }
  function checkPasswordConfirmInput() {
    if (!passwordConfirm.value) {
      setErrorFor(passwordConfirm, "Por favor, confirme su contraseña");
      return false;
    }
    else if (passwordConfirm.value !== password.value) {
      setErrorFor(passwordConfirm, "las contraseñas no coinciden");
      return false;
    } else {
      setSuccessFor(passwordConfirm);
      return true;
    }
  }
};
